/**
 * Created by glenne on 6/10/2017.
 */
import { Card, CardContent, Fab, Grid, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import ContentAddIcon from '@mui/icons-material/Add';
import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import KeyMap from './shared/KeyMap';
import { useTranslation } from 'react-i18next';
import { ResultSummary } from './shared/CrewTimerTypes';
import Util from './shared/Util';
import { useNavigate } from 'react-router';
import { _setRegattaTitle } from './shared/UseResults';
import { N_NUM_ENTRIES } from './shared/Names';

const useStyles = makeStyles((theme) => ({
  page: { marginLeft: theme.spacing(2), marginRight: theme.spacing(2) },
  search: { marginBottom: theme.spacing(2) },
  card: { marginBottom: theme.spacing(2) },
  footer: { marginTop: theme.spacing(1) },
  TableHead: {
    fontWeight: 'bold',
    fontSize: '1em',
    height: '36px',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    paddingTop: '2px',
    paddingBottom: '2px',
    color: 'rgb(108, 125, 150)',
  },
  TableHeadFixed: {
    fontWeight: 'bold',
    fontSize: '1em',
    width: '7em',
    height: '36px',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    paddingTop: '2px',
    paddingBottom: '2px',
    color: 'rgb(108, 125, 150)',
  },
  TableHeadFee: {
    fontWeight: 'bold',
    fontSize: '1em',
    width: '8em',
    height: '36px',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    paddingTop: '2px',
    paddingBottom: '2px',
    color: 'rgb(108, 125, 150)',
  },
  TableCell: {
    fontSize: '1em',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontWeight: 'normal',
    height: '36px',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    paddingTop: '2px',
    paddingBottom: '2px',
    color: 'black',
  },
  TableCellFixed: {
    fontSize: '1em',
    width: '7em',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    fontWeight: 'normal',
    height: '36px',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    paddingTop: '2px',
    paddingBottom: '2px',
    color: 'black',
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(1),
    marginTop: '8px',
    marginBottom: 0,
    width: 200,
  },
  textLabel: {},

  odd: { backgroundColor: '#f0f0f0' },
  even: { backgroundColor: '#ffffff' },
}));

const twoDigitNum = (num: number) => `${num < 10 ? '0' : ''}${num}`;
interface CardTitleProps { }

export const CardTitle: FunctionComponent<PropsWithChildren<CardTitleProps>> = (props) => {
  return (
    <Typography gutterBottom variant="h6" component="h2">
      {props.children}
    </Typography>
  );
};

export const RegattaLine: FunctionComponent<{ rowNum: number; row: KeyMap; onClick: () => void }> = (props) => {
  const classes = useStyles();
  const { rowNum, row, onClick } = props;
  const numDays = Number(row.NumDays || 1);
  const numDaysString = numDays !== 1 ? ` (${numDays}Days)` : '';
  const numEntries = Number(row[N_NUM_ENTRIES] || 0);
  const feeType = row.FeeType || 'Fee';
  const cost = (numEntries < 10 || feeType !== 'Fee') ? 0 : numEntries / 2;
  const payment = row.Payment;
  const isAdmin = Util.isUserAdmin();
  const old = new Date(row.Date) < new Date('2025-01-01');
  const feeText = old ? '' : payment ? 'Paid' : cost ? `$${cost.toFixed(2)}` : 'Free';
  const feeLinkUser = feeText ? <a href={`/payment/${row.Name}`} target="_blank" rel="noopener noreferrer" > {feeText}</a > : <></>
  const feeLinkAdmin = isAdmin && feeText ? <div>&nbsp;<a href={`/payment/${row.Name}`} target="_blank" rel="noopener noreferrer" >{feeText}</a ></div> : <></>

  return (
    <TableRow className={rowNum % 2 ? classes.odd : classes.even} key={`${row.Date}-${row.Name}`}>
      <TableCell className={classes.TableCellFixed} onClick={onClick}>
        <Button variant="outlined" size="small">
          Manage
        </Button>
      </TableCell>
      <TableCell className={classes.TableCellFixed}>{`${row.Date}${numDaysString}`}</TableCell>
      <TableCell className={classes.TableCellFixed}>{`${row.Name}`}</TableCell>
      <TableCell className={classes.TableCellFixed}>{`${row.NumEntries}`}{feeLinkAdmin}</TableCell>
      <TableCell className={classes.TableCell}>{row.Title}</TableCell>
      {isAdmin && <TableCell className={classes.TableCell}>{row.Owner}</TableCell>}
      {!isAdmin && <TableCell className={classes.TableCell}>
        {feeLinkUser}
      </TableCell>}
    </TableRow>
  );
};

export const RegattaTable: FunctionComponent<{
  title: string;
  footer?: string;
  rows: ResultSummary[];
  onClick: (row: any) => void;
}> = (props) => {
  const { title, footer, rows, onClick } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const isAdmin = Util.isUserAdmin();

  if (rows.length === 0) {
    return null;
  }
  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.TableHeadFixed}>{t('Action')}</TableCell>
              <TableCell className={classes.TableHeadFixed}>{t('Date')}</TableCell>
              <TableCell className={classes.TableHeadFixed}>{t('MobileID')}</TableCell>
              <TableCell className={classes.TableHeadFixed}>{t('Entries')}</TableCell>
              <TableCell className={classes.TableHead}>{t('Title')}</TableCell>
              {isAdmin && <TableCell className={classes.TableHeadFixed}>{t('Owner')}</TableCell>}
              {!isAdmin && <TableCell className={classes.TableHeadFee}>{t('Suggested Timing Fee')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return <RegattaLine rowNum={i} key={row.Name} row={row} onClick={() => onClick(row)}></RegattaLine>;
            })}
          </TableBody>
        </Table>
        {footer && <Typography className={classes.footer}>{footer}</Typography>}
      </CardContent>
    </Card>
  );
};
export const RegattaList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const classes = useStyles();
  const [regattaList, setRegattaList] = useState([] as ResultSummary[]);
  const t = (text: string) => text; // shim for useTranslate
  useEffect(() => {
    const changeRef = Util.onRegattaSummaryChange((values) => {
      // sort by date
      const regattaSummaryList = values
        .sort((a, b) => {
          if (a.Date < b.Date) {
            return -1;
          } else if (a.Date === b.Date) {
            return a.Title === b.Title ? 0 : a.Title < b.Title ? -1 : 1;
          }
          return 1;
        })
        .reverse();
      setRegattaList(regattaSummaryList);
    });
    return () => {
      Util.offRegattaResultsChange(changeRef);
    };
  }, []);

  const onRegattaSelected = (regattaId: string) => {
    navigate(`/manage/${regattaId}`);
  };

  const showRegattaClicked = (row) => {
    // let row = e.currentTarget.rowIndex - 1;
    const regatta = row.Name;
    if (onRegattaSelected) {
      onRegattaSelected(regatta);
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const onAddRegatta = () => {
    _setRegattaTitle('Create Event');
    navigate('/edit/new');
  };

  const now = new Date();
  const nowDate = `${now.getFullYear()}-${twoDigitNum(now.getMonth() + 1)}-${twoDigitNum(now.getDate())}`;
  const nowMilli = new Date(nowDate).getTime();

  const searchLower = search.toLowerCase();
  const todayList: ResultSummary[] = [];
  let futureList: ResultSummary[] = [];
  let pastList: ResultSummary[] = [];

  regattaList.forEach((row) => {
    const firstMilli = new Date(row.Date).getTime();
    const lastMilli = firstMilli + (Number(row.NumDays || 1) - 1) * 1000 * 60 * 60 * 24;
    if (nowMilli >= firstMilli && nowMilli <= lastMilli) {
      todayList.push(row);
    }
    if (nowMilli > lastMilli) {
      pastList.push(row);
    }
    if (nowMilli < lastMilli) {
      futureList.push(row);
    }
  });
  futureList = futureList.reverse();
  pastList = pastList.slice(0, Math.max(30, futureList.length));
  let searchList: ResultSummary[] = [];
  if (searchLower.length > 0) {
    searchList = regattaList.filter((row) => row.Title.toLowerCase().indexOf(searchLower) >= 0);
    todayList.length = 0;
    futureList.length = 0;
    pastList.length = 0;
  }
  const oneColumn = todayList.length === 0 && futureList.length === 0;
  return (
    <div className={classes.page}>
      <div className={classes.search}>
        <TextField
          id="standard-search"
          variant="standard"
          label={t('Search races')}
          type="search"
          className={classes.textField}
          onChange={handleSearch}
          value={search}
          margin="normal"
        />
        <Tooltip title="Add Event">
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            onClick={onAddRegatta}
            sx={{ marginLeft: '1em', marginTop: '1em' }}
          >
            <ContentAddIcon />
          </Fab>
        </Tooltip>
      </div>
      {regattaList.length === 0 ? <p>Click the add icon to add your first race.</p> : null}
      <Grid container spacing={2}>
        {oneColumn ? (
          <Grid item xs={12}>
            <RegattaTable
              title={t('Past Events')}
              footer={t('Use search to find older events')}
              rows={pastList}
              onClick={showRegattaClicked}
            />
            <RegattaTable title={t('Search Events')} rows={searchList} onClick={showRegattaClicked} />
          </Grid>
        ) : (
          [
            <Grid key="1" item xs={12} sm={6}>
              <RegattaTable title={t("Today's Events")} rows={todayList} onClick={showRegattaClicked} />
              <RegattaTable title={t('Upcoming Events')} rows={futureList} onClick={showRegattaClicked} />
              <RegattaTable title={t('Search Events')} rows={searchList} onClick={showRegattaClicked} />
            </Grid>,
            <Grid key="2" item xs={12} sm={6}>
              <RegattaTable
                title={t('Past Events')}
                footer={t('Use search to find older results')}
                rows={pastList}
                onClick={showRegattaClicked}
              />
            </Grid>,
          ]
        )}
      </Grid>
    </div>
  );
};

export default RegattaList;
